export default function SessionModal({ logOut }: { logOut: () => void }) {
  return (
    <div className='fixed inset-0 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 z-[999999999]'>
      <div className='p-3.5 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-[400px] bg-white dark:bg-gray-700 rounded-md z-[31]'>
        <h1 className='text-lg text-center font-semibold text-red-500 uppercase dark:text-red-400'>Session is over!</h1>
        <p className='my-5 text-sm text-center'>Your session is over. You must log in again to use the website.</p>

        <div className='flex'>
          <button
            type='button'
            onClick={logOut}
            className='mt-4 px-8 py-3 h-max w-full text-white font-medium rounded-lg text-center text-xs bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
            Login
          </button>
        </div>
      </div>
    </div>
  )
}