export default function switchTheme(param: 'switch' | 'get') {
  if (param === 'get') {
    if (localStorage?.getItem('$flakonuzadmindashboard$theme$')) {
      if (localStorage?.getItem('$flakonuzadmindashboard$theme$') === 'dark') {
        document?.documentElement?.classList.add('dark')
        document?.documentElement?.classList.remove('light')
        document.documentElement.style.colorScheme = 'dark'
      } else {
        document?.documentElement?.classList.add('light')
        document?.documentElement?.classList.remove('dark')
        document.documentElement.style.colorScheme = 'light'
      }
    }
  } else if (param === 'switch') {
    if ((localStorage?.getItem('$flakonuzadmindashboard$theme$') && localStorage?.getItem('$flakonuzadmindashboard$theme$') === 'dark') || document?.documentElement?.className.includes('dark')) {
      localStorage?.setItem('$flakonuzadmindashboard$theme$', 'light')
      document?.documentElement?.classList.add('light')
      document?.documentElement?.classList.remove('dark')
      document.documentElement.style.colorScheme = 'light'
    } else {
      localStorage?.setItem('$flakonuzadmindashboard$theme$', 'dark')
      document?.documentElement?.classList.add('dark')
      document?.documentElement?.classList.remove('light')
      document.documentElement.style.colorScheme = 'dark'
    }
  }
}